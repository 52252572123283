const state = () => ({
    all: { data: { Phone: "99999999999999999" } }
})

// getters
const getters = {
    getCallPhone: state => {
        return state.all.data.Phone
    },
    getCallData: state => {
        return state.all.data
    }
}

// actions
const actions = {
    async setCallPhone(context, Phone) {
        context.commit('SET_CALL_PHONE', Phone)
        //console.log("setCallPhone", CallNumber)

    },
    async setCallData(context, data) {
        context.commit('SET_CALL_PHONE', data)
        //console.log("setCallPhone", CallNumber)

    }
}

// mutations
const mutations = {
    CLEAR_ALL(state) {
        state.all = {}
    },
    SET_CALL_PHONE(state, Phone) {
        state.all.data.Phone = Phone
        //console.log("SET_CALL_PHONE", CallNumber)

    },
    SET_CALL_DATA(state, data) {
        state.all.data = data
        //console.log("SET_CALL_PHONE", CallNumber)
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}