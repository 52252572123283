<template>
  <div class="user-company-root">

    <div class="user-company-block">
      <div style="height: 16em;">
        <div v-if="loadingUsersCompany" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsUsersCompany" :items="itemsUsersCompany"
          v-model:current="currentItemUsersCompany" />
      </div>
      <div class="panel">
        <mo-paginator-2 v-bind:currentPage="currentPageUsersCompany" :maxPage="maxPageUsersCompany"
          @pageChange="pageChangeUsersCompany" />
      </div>
    </div>
    <hr style="border: 1px solid white; margin-block-end:3px;" />
    <div class="call-history-block">
      <div style="height: 16em;">
        <div v-if="loadingCallHistory" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsCallHistory" :items="itemsCallHistory"
          @update:current="changeCurrentItemCallHistory" :current="currentItemCallHistory" :selected="selected" />
      </div>
      <div class="panel">
        <mo-paginator :count="pagesCallHistory" :page="pageCallHistory" @change="loadCallHistory" />
      </div>
      <div class="panel-oneline">
        <div style="margin-right: 20px;">
          <mo-select2 :currentValue="currentTypeComment" @update:currentValue="changeCurrentTypeComment"
            :width="'265px'" :options="typeComment" />
        </div>
        <mo-input :modelValue="currentCommentCallHistory" @update:modelValue="setComment"
          :placeholder="placeholderComment" :minwidth="'77%'" :maxlength="'255'" />
        <div class="button-block">
          <mo-button @click="saveCommentCallHistory" type="success" :disabled="disableSave" :width="'26px'">
            <mo-icon name="save" />
          </mo-button>
          <mo-button @click="loadCallHistory(1)" type="success" :width="'26px'">
            <mo-icon name="refresh" />
          </mo-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const columnsUsersCompany = [
  { name: "id", title: "№", width: 10 },
  { name: "name", title: "ФИО", width: 100 },
  { name: "email", title: "Почта", width: 100 },
  { name: "phone", title: "Телефон", width: 100 },
  { name: "blocked", title: "Заблокирован", type: Boolean, width: 10 },
];
const columnsCallHistory = [
  { name: "Id", title: "№", width: 10 },
  { name: "CallerIDNum", title: "От кого", width: 100 },
  { name: "DestCallerIDNum", title: "Кому", width: 100 },
  { name: "StartTime", title: "Начало звонка", width: 100 },
  { name: "AnswerTime", title: "Начало разговора", width: 100 },
  { name: "Duration", title: "Продолжительность", width: 100 },
  { name: "DialStatus", title: "Статус", width: 100 },
  { name: "Uniqueid", title: "№ События", width: 100 },
];

export default {
  name: 'call-center-tab1',
  props: {
    companyItem: {
      type: Object,
    }
  },
  data() {
    return {
      //Переменные для верхней таблицы пользоатели компании
      columnsUsersCompany,
      itemsUsersCompany: [],
      loadingUsersCompany: false,
      currentPageUsersCompany: 0,
      maxPageUsersCompany: false,
      limitUsersCompany: 6,
      offsetUsersCompany: 0,
      currentItemUsersCompany: [],
      //Переменные для нижней таблицы исория звонков
      columnsCallHistory,
      itemsCallHistory: [],
      loadingCallHistory: false,
      currentItemCallHistory: {},
      limitCallHistory: 6,
      offsetCallHistory: 0,
      userPhoneCallHistory: this.$store.getters["user/getUser"]?.phoneInternal,
      //      userPhoneCallHistory: this.$store.getters["call_center/getCallPhone"],
      pageCallHistory: 1,
      pagesCallHistory: 1,
      currentCommentCallHistory: "",
      commentCallHistory: "",
      disableSave: true,
      disabelInput: true,
      typeComment: [],
      currentTypeComment: "0",
      placeholderComment: "Написанный сейчас комментарий, будет относится к текущему звонку!",
      selected: undefined,
    }
  },
  methods: {
    async changeCurrentTypeComment(event) {
      console.log("chanegCurrentTypeComment", event.target.value)
      if (this.currentTypeComment != event.target.value) this.disableSave = false;
      this.currentTypeComment = event.target.value;

      //this.currentTypeComment=4;
    },
    async changeCurrentItemCallHistory(event) {
      this.selected = undefined;
      this.currentItemCallHistory = event;
      this.currentTypeComment = event.TypeComment;
      this.getCommentCallHistory();
      console.log(event.TypeComment, this.currentTypeComment, this.currentTypeComment);
    },
    async pageChangeUsersCompany(event) {
      this.currentPageUsersCompany = event;
      this.loadUsersCompany();
    },
    async loadUsersCompany() {
      this.loadingUsersCompany = true;

      const { success, message, data: responseData } = await this.$http.getCompanyUsersById(
        this.companyItem.Id,
        this.limitUsersCompany,
        this.currentPageUsersCompany * 6
      );

      this.itemsUsersCompany = responseData || [];
      if (this.itemsUsersCompany.length > 0) this.maxPageUsersCompany = false;
      if (this.itemsUsersCompany.length < 6) this.maxPageUsersCompany = true;

      this.loadingUsersCompany = false;
    },
    async loadCallHistory(page = this.pageCallHistory, mounted = false) {
      this.loadingCallHistory = true;
      //console.log("getCallHistory",this.$store.getters["user/getUser"])
      const { success, message, data: responseData } = await this.$http.getCallHistory(
        this.userPhoneCallHistory,
        this.companyItem.Phone.replace(/\D/g, '').slice(-9),
        this.limitCallHistory,
        page
      );

      this.itemsCallHistory = responseData?.data || [];
      this.pageCallHistory = responseData?.meta?.page || 0;
      this.pagesCallHistory = responseData?.meta?.last_page || 0;
      this.totalItemsCallHistory = responseData?.meta?.total || 0;

      this.loadingCallHistory = false;
      if (!mounted) {
        //Делаем активной последнюю запись
        this.currentItemCallHistory = this.itemsCallHistory[0]
        //Выделяем её
        this.selected = 0;
        //Загружаем коментарий послдней записи
        this.currentCommentCallHistory = this.itemsCallHistory[0].Comment;
        this.currentTypeComment = this.itemsCallHistory[0].TypeComment;
      }
    },
    async getCommentCallHistory() {
      //this.disableSave = true;
      // const { success, message, data: responseData } = await this.$http.getCommentCallHistory(
      //   this.currentItemCallHistory.Id
      // );
      // this.currentCommentCallHistory = responseData;
      this.currentCommentCallHistory = this.currentItemCallHistory.Comment;
      this.disabelInput = false;
      this.placeholderComment = 'Комментарий на звонок № ' + this.currentItemCallHistory.Id;
      //if (success) this.disabelInput = false;
    },
    async getTypeCommentCallHistory() {
      const { data: responseData } = await this.$http.getTypeCommentCallHistory();
      let d = []
      responseData.forEach((item, i) => {
        d.push({ 'text': item.TypeComment, 'value': item.Id })
      })
      this.typeComment = d;
    },
    async saveCommentCallHistory() {
      const { success, message, data: responseData } = await this.$http.saveCommentCallHistory(
        this.currentItemCallHistory.Uniqueid || this.$store.getters["call_center/getCallData"].UniqueId,
        this.currentCommentCallHistory,
        this.currentTypeComment
      );
      if (success) {
        let d = this.itemsCallHistory;
        d.forEach((item, i) => {
          if (item.Id == this.currentItemCallHistory.Id) {
            this.itemsCallHistory[i].Comment = this.currentCommentCallHistory;
            this.itemsCallHistory[i].TypeComment = this.currentTypeComment;
          }
        });
        this.$notify({ title: "Комментарий сохранен" });
        if (this.currentItemCallHistory.Uniqueid === undefined) {
          //Если комментарий был к текущему звонку, тогда обновляем историю.
          this.loadCallHistory(1);
          //Делаем активной последнюю запись
          this.currentItemCallHistory = this.itemsCallHistory[0]
          //Выделяем её
          this.selected = 0;
          //Загружаем коментарий послдней записи
          this.currentCommentCallHistory = this.itemsCallHistory[0].Comment;
          this.currentTypeComment = this.itemsCallHistory[0].TypeComment;

          console.log('AAAAAAAAAAAAA', this.currentItemCallHistory, this.currentCommentCallHistory, this.currentTypeComment)
        }
        this.disableSave = true;
      } else {
        this.$notify({ title: "Ошибка сохраниения" });
      }
    },
    async setComment(comment) {
      if (this.commentCallHistory != comment) this.disableSave = false;
      this.currentCommentCallHistory = comment;
    }
  },
  mounted() {
    this.getTypeCommentCallHistory();
    this.loadUsersCompany();
    this.loadCallHistory(1, true);
  },
  watch: {
    currentTypeComment(v) {
      console.log("WATCH", v);
    }
    //   currentItemCallHistory(v) {
    //     console.log("WATCH")
    //     this.getCommentCallHistory();
    //   }
  },
}
</script>

<style lang="scss" scoped>
.user-company-root {
  width: 100%;
  height: 100%;

  .user-company-block {
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, stretch);

    .panel {
      @include flex(column, space-between, right);
    }

    .loader {
      padding: 50px 0;
      @include flex();
    }
  }

  .call-history-block {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    @include flex(column, flex-start, stretch);

    .panel {
      @include flex(column, space-between, right);
    }

    .loader {
      padding: 50px 0;
      @include flex();
    }
  }

  .panel-oneline {
    @include flex(row, space-between, right);
    margin-top: 40px;
  }
}

.button-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
  min-width: 70px;
  margin-top: 0px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;

  border: 0px;
  border-style: solid;
}
</style>
