import Axios from 'axios'

export default {
    install(app, options) {

        var SOCKET
        var PHONE_INTERNAL = "0"
        const CACHE = caches.open("v1");

        function connect() {

            SOCKET = new WebSocket(`${process.env.VUE_APP_WS_HOST}/` + PHONE_INTERNAL);
            //console.log(SOCKET);

            SOCKET.onopen = function () {
                ("Соединение установлено.");
            };

            SOCKET.onclose = function (event) {
                if (event.wasClean) {
                    console.log('Соединение закрыто чисто');
                } else {
                    console.log('Обрыв соединения'); // например, "убит" процесс сервера
                }
                console.log('Код: ' + event.code + ' причина: ' + event.reason);
            };

            SOCKET.onmessage = function (event) {
                var jsonData = JSON.parse(event.data);
                var phone = jsonData["Phone"];
                console.log("Получены данные " + event.data, "Телефон " +phone);

                if( jsonData !== undefined ){
                    app.config.globalProperties.$store.commit('call_center/SET_CALL_DATA', jsonData)
                }

            };

            SOCKET.onerror = function (error) {
                console.log("Ошибка " + error.message);
            };
        }

        setInterval(function () {
            if (SOCKET != null && SOCKET.readyState === WebSocket.CLOSED && PHONE_INTERNAL !== "0") {
                connect();
                console.log("Tick");
            }
        }, 1000)

        const service = Axios.create(options)

        const api = async options =>
            await service(options)
                .then(response => {
                    const { data, status } = response
                    return ({ error: undefined, data, status })
                })
                .catch(error => {
                    const { data, status } = error.response || {}
                    return ({ error, data, status })
                })

        const anyErrorText = 'Что-то пошло не так'

        const store = app.config.globalProperties.$store
        var now = new Date();

        const http = {
            //common
            get: async (url, params, options) => await api({ ...options, ...{ method: 'get', url, params } }),
            post: async (url, data, options) => await api({ ...options, ...{ method: 'post', url, data } }),
            put: async (url, data, options) => await api({ ...options, ...{ method: 'put', url, data } }),
            delete: async (url, data, options) => await api({ ...options, ...{ method: 'delete', url, data } }),
            //auth
            login: async (requestData) => {
                const { error, data = {}, status } = await api({ method: 'post', url: '/auth/login', data: requestData })
                const message =
                {
                    type: !error ? "success" : "alert",
                    text: data.message || (error ? anyErrorText : 'Вы успешно авторизованы.'),
                    extra_params: data.extra_params
                };
                const d = data
                return { success: !error, message, data }
            },
            logout: async () => {
                const { error, data = {}, status } = await api({ method: 'get', url: '/auth/logout' })
                return { success: !error, message: {}, data: {} }
            },
            user: async () => {
                const { error, data = {}, status } = await api({ method: 'get', url: '/auth/user' })
                console.log(data,"USER")
                if (!error) {
                    PHONE_INTERNAL = data.phoneInternal
                    connect()
                }
                return { success: !error, message: {}, data }
            },
            fillPolicy: async () => {
                const { error, data = {}, status } = await api({ method: 'get', url: '/auth/policy', user_id: app.config.globalProperties.$store.getters['user/getRole'] })
                //fill policy to vuex
                //console.log(data)
                app.config.globalProperties.$store.commit('policy/CLEAR_ALL', data)
                app.config.globalProperties.$store.commit('policy/SET_POLICY', data)
                return { success: !error, message: {}, data }
            },

            //employees
            getEmployees: async (page, order, reverse, query, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: '/employee',
                    params: { page, order, reverse, query, user_id }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getFeedbacks: async (page, dateRange, order, reverse, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: '/employee/get_feedbacks',
                    params: { page, order, reverse, user_id, from: dateRange.from.getTime(), to: dateRange.to.getTime() }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getFeedbackByID: async (id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: '/employee/get_feedback_by_id',
                    params: { id, user_id }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getEmployeeById: async (employee_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/employee/info`, params: { employee_id } })
                return { success: !error, message: {}, data: responseData }
            },
            createEmployee: async (requestData) => {
                const { error, data: responseData = {}, status } = await api({ method: 'post', url: `/employee`, data: requestData })
                return { success: !error, message: {}, data: responseData }
            },
            editEmployee: async (employee_id, requestData) => {
                const { error, data: responseData = {}, status } = await api({ method: 'put', url: `/employee`, data: requestData, params: { employee_id } })
                return { success: !error, message: {}, data: responseData }
            },
            deleteEmployee: async (employee_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'delete', url: `/employee`, params: { employee_id } })
                return { success: !error, message: {}, data: responseData }
            },
            //hunting
            getHunting: async (page, order, reverse, query, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: `/hunting`,
                    params: { page, order, reverse, query, user_id }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getHuntingById: async (hunting_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/hunting/info`, params: { hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            getHuntingByInn: async (hunting_inn) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/hunting/get-hunting-by-inn`, params: { hunting_inn } })
                return { success: !error, message: {}, data: responseData }
            },
            createHunting: async (data) => {
                const { error, data: responseData = {}, status } = await api({ method: 'post', url: `/hunting`, data })
                return { success: !error, message: {}, data: responseData }
            },
            editHunting: async (hunting_id, data) => {
                const { error, data: responseData = {}, status } = await api({ method: 'put', url: `/hunting`, data, params: { hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            deleteHunting: async (hunting_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'delete', url: `/hunting`, params: { hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            //company
            getCompany: async (page, dateRange, order, reverse, query, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: `/company`,
                    params: { page, user_id, order, reverse, query, from: dateRange.from.getTime(), to: dateRange.to.getTime() }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getCompanyById: async (company_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: '/company/info', params: { company_id } })
                return { success: !error, message: {}, data: responseData }
            },
            //API xt-xarid
            getRefProceduresCrmById: async (company_id, limit, offset) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: '/xt-xarid/get_ref_procedures_crm', params: { company_id, limit, offset } })
                return { success: !error, message: {}, data: responseData }
            },
            getBillingOperations: async () => {
                let cache = app.config.globalProperties.$store.getters["cache/getCacheByName"]("billingOperationsMap");
                if (cache.length === 0) {
                    const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_billing_operations' });
                    if (!error) app.config.globalProperties.$store.commit('cache/SET_CACHE', { key: 'billingOperationsMap', data: data });

                    return { success: !error, message: {}, data: data?.result }
                } else {
                    return { success: true, message: {}, data: cache?.result }
                }
            },
            getRefBillingStatus: async () => {
                let cache = app.config.globalProperties.$store.getters["cache/getCacheByName"]("billingStatusMap");
                if (cache.length === 0) {
                    const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_ref_billing_status' })
                    if (!error) app.config.globalProperties.$store.commit('cache/SET_CACHE', { key: 'billingStatusMap', data: data });

                    return { success: !error, message: {}, data: data?.result }
                } else {
                    return { success: true, message: {}, data: cache?.result }
                }
            },
            getRefStatusLots: async () => {
                let cache = app.config.globalProperties.$store.getters["cache/getCacheByName"]("lotsStatusMap");
                if (cache.length === 0) {
                    const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_ref_status_lots' });
                    if (!error) app.config.globalProperties.$store.commit('cache/SET_CACHE', { key: 'lotsStatusMap', data: data });
                    return { success: !error, message: {}, data: data }
                } else {
                    return { success: true, message: {}, data: cache }
                }
            },
            getCompanyAttrById: async (company_id) => {
                const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/team_attr', params: { company_id } })
                return { success: !error, message: {}, data: data?.result }
            },
            getCompanyBalanceById: async (company_id) => {
                const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_company_balance', params: { company_id } })
                return { success: !error, message: {}, data: data?.result }
            },
            getCompanyUsersById: async (company_id, limit, offset) => {
                const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_company_users', params: { company_id, limit, offset } })
                return { success: !error, message: {}, data: data?.result }
            },
            getAccountInfoById: async (company_id, limit, offset, query, date_begin, date_end, op_type) => {
                const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_account_info', params: { company_id, limit, offset, query, date_begin, date_end, op_type } })
                return { success: !error, message: {}, data: data?.result }
            },
            getBillingExportsById: async (company_id, limit, offset, date_begin, date_end, op_status) => {
                const { error, data, status } = await api({ method: 'get', url: '/xt-xarid/get_billing_exports', params: { company_id, limit, offset, date_begin, date_end, op_status } })
                return { success: !error, message: {}, data: data?.result }
            },
            // END API xt-xarid

            //Колцентр
            getCallHistory: async (user_phone, company_phone, limit, page) => {
                //console.log("getCallHistory:",user_phone, company_phone, limit, page)
                const { error, data, status } = await api({ method: 'get', url: '/call/get_call_history', params: { user_phone, company_phone, limit, page} })
                return { success: !error, message: {}, data }
            },
            getCommentCallHistory: async (comment_id) => {
                const { error, data, status } = await api({ method: 'get', url: '/call/get_call_history_comment_by_id', params: { comment_id } })
                return { success: !error, message: {}, data }
            },
            getTypeCommentCallHistory: async () => {
                let cache = app.config.globalProperties.$store.getters["cache/getCacheByName"]("typeCommentCallHistoryMap");
                if (cache.length === 0) {
                    const { error, data, status } = await api({ method: 'get', url: '/call/get_call_history_comment_type' });
                    if ((error === undefined) || !error){
                            app.config.globalProperties.$store.commit('cache/SET_CACHE', { key: 'typeCommentCallHistoryMap', data: data });}

                    return { success: !error, message: {}, data: data }
                } else {
                    return { success: true, message: {}, data: cache }
                }
            },
            saveCommentCallHistory: async (comment_id, comment, type_comment) => {
                console.log("saveCommentCallHistory",comment_id, comment, type_comment);
                const { error, data, status } = await api({ method: 'post', url: '/call/save_call_history_comment_by_uniqueid', params: { comment_id, comment, type_comment } })
                return { success: !error, message: {}, data }
            },
            //END Колцентр


            getCompanyByPhone: async (phone, page, user_id, order, reverse, query,) => {
                //console.log("getCompanyByPhone", phone)
                const { error, data: responseData = {}, status } = await api({
                    method: 'get', url: `/companies/phone`,
                    params: { phone, page, user_id, order, reverse, query, }
                })
                return { success: !error, message: {}, data: responseData }
            },
            createCompany: async (company_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'post', url: `/company`, params: { company_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            editCompany: async (company_id, data, user_id = store.getters['user/getManagedUser'].id) => {
                //console.log(company_id, data, user_id);
                const { error, data: responseData = {}, status } = await api({ method: 'put', url: `/company`, data, params: { company_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            deleteCompany: async (company_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'delete', url: `/company`, params: { user_id, company_id } })
                return { success: !error, message: {}, data: responseData }
            },
            companyByINN: async (inn) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/company/get-company-by-inn`, params: { inn } })
                return { success: !error, message: {}, data: responseData }
            },
            companyById: async (company_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/company/info`, params: { company_id } })
                return { success: !error, message: {}, data: responseData }
            },
            companyByInnOrg: async (inn, org_code) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/company/get-company-by-inn-org`, params: { inn, org_code } })
                return { success: !error, message: {}, data: responseData }
            },
            //employee hunting
            getEmployeeHunting: async (page, dateRange, order, reverse, query, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: `/employee_hunting`,
                    params: { page, user_id, order, reverse, query, from: dateRange.from.getTime(), to: dateRange.to.getTime() }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getEmployeeHuntingById: async (hunting_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/employee_hunting/info`, params: { user_id, hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            createEmployeeHunting: async (hunting_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'post', url: `/employee_hunting`, data: {}, params: { hunting_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            editEmployeeHunting: async (hunting_id, data, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'put', url: `/employee_hunting`, data, params: { hunting_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            deleteEmployeeHunting: async (hunting_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'delete', url: `/employee_hunting`, params: { user_id, hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            searchHuntingByID: async (hunting_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/hunting/info`, params: { hunting_id } })
                return { success: !error, message: {}, data: responseData }
            },
            //procedure
            getProcedure: async (page, dateRange, order, reverse, query, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({
                    method: 'get',
                    url: `/procedure`,
                    params: { page, user_id, order, reverse, query, from: dateRange.from.getTime(), to: dateRange.to.getTime() }
                })
                return { success: !error, message: {}, data: responseData }
            },
            getProcedureByID: async (proc_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/procedure/info`, params: { proc_id } })
                return { success: !error, message: {}, data: responseData }
            },
            createProcedure: async (procedure_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'post', url: `/procedure`, params: { procedure_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            editProcedure: async (procedure_id, data, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'put', url: `/procedure`, data, params: { procedure_id, user_id } })
                return { success: !error, message: {}, data: responseData }
            },
            deleteProcedure: async (procedure_id, user_id = store.getters['user/getManagedUser'].id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'delete', url: `/procedure`, params: { user_id, procedure_id } })
                return { success: !error, message: {}, data: responseData }
            },
            //FIND
            findProc: async (procedure_id) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/find/proces`, params: { proc_id: procedure_id } })
                return { success: !error, message: {}, data: responseData }
            },
            findComp: async (company_inn) => {
                const { error, data: responseData = {}, status } = await api({ method: 'get', url: `/find/companies`, params: { inn: company_inn } })
                return { success: !error, message: {}, data: responseData }
            },
        }

        app.config.globalProperties.$axios = service
        app.provide('axios', service)

        app.config.globalProperties.$http = http
        app.provide('http', http)
    }
}