<template>
  <div class="mo-table-root" @mouseleave="active = undefined">
    <div v-for="column in columns" :key="column.name" :title="column.name" :style="sizeStyles[column.name]">
      <div v-if="column.ordered === false" :title="column.name" @mouseover="active = undefined">
        <span>{{ column.title }}</span>
        <mo-icon v-if="column.name === order && reverse" />
      </div>
      <div v-else :title="column.name" @mouseover="active = undefined" @click="setSort(column.name)">
        <span>{{ column.title }}</span>
        <mo-icon v-if="column.name === order && reverse" :name="{ desc: 'chevrondown', asc: 'chevronup' }[reverse]" />
      </div>

      <div v-for="(item, index) in items" :key="item[columns[0].name]"
        :class="{ 'active_row': current == item || selected == index }" @mouseover="active = item"
        @click="$emit('update:current', item)">
        <input v-if="column.type === Boolean" type="checkbox" :checked="item[column.name]" disabled="true" />
        <span v-if="column.type === undefined">{{ item[column.name] || "" }}</span>
        <span v-if="column.type === 'Date'">{{ item[column.name].slice(0, 10) || "" }}</span>
        <span v-if="column.type === 'DateTime'">{{ item[column.name].slice(0, 10) + ' ' + item[column.name].slice(11, 19) ||
          "" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mo-table",
  emits: ["update:current", "sort"],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    current: {
      type: Object,
    },
    order: {
      type: String,
    },
    reverse: {
      type: String,
    },
    selected: {
      type: Number,
    }
  },
  data() {
    return {
      active: undefined,
    };
  },
  computed: {
    sizeStyles() {
      const styles = {};
      this.columns.forEach(
        (column) => {
          if (typeof (column.width) === "string") {
            styles[column.name] = {
              width: column.width,
              flexBasis: column.width,
            }
          } else {
            styles[column.name] = {
              width: `${column.width}px`,
              flexBasis: `${column.width}px`,
            }
          }
        }
      );
      return styles;
    },
  },
  methods: {
    setSort(order) {
      const reverse =
        order === this.order
          ? { desc: "asc", asc: "desc" }[this.reverse]
          : "desc";
      this.$emit("sort", { order, reverse });
    },
  },
};
</script>

<style lang="scss" scoped>
.active_row {
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
}

.mo-table-root {
  @include flex(row, flex-start, stretch);
  margin: 10px 0;
  border-radius: 5px;
  overflow: hidden;

  >div {
    @include flex(column, flex-start, stretch);
    flex-grow: 1;
    flex-shrink: 1;

    >div {
      @include flex();
      min-height: 34px;
      height: 34px;
      max-height: 34px;
      cursor: pointer;
      background-color: lighten($mo-var_main_panel-background-color, 55%);
      transition: background-color 50ms linear;
      font-size: 16px;
      line-height: 18px;
      color: black;

      &:first-child {
        min-height: 37px;
        height: 37px;
        max-height: 37px;
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;
        border-bottom: 3px solid $mo-var_main_background-color;

        >svg {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: lighten($mo-var_main_panel-background-color, 45%);
        }
      }

      &:nth-child(odd) {
        background-color: lighten($mo-var_main_panel-background-color, 60%);
      }

      &.active {
        background-color: lighten($mo-var_main_panel-background-color, 45%);
      }

      >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:not(:first-child) {
        >input:disabled {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
