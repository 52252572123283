<template>
  <div class="cliring-root">
    <div class="panel">
      <div v-if="loadingBalanceCompany" class="loader">
        <mo-loader />
      </div>
      <div class="balance">
        <span>Общая сумма средств: {{ companyBalance[0]?.total_funds }}</span>
        <span>Из них свободных средств: {{ companyBalance[0]?.free_funds }}</span>
        <span>Из них заблокированных средств: {{ companyBalance[0]?.blocked_funds }}</span>
        <span>Из них средств к выводу: {{ companyBalance[0]?.export_funds }}</span>
        <span>Долг: {{ companyBalance[0]?.free_funds }}</span>
        <span>Валюта: {{ companyBalance[0]?.currency }}</span>
      </div>
    </div>

    <div class="account-info-block">
      <div style="height: 16em;">
        <div v-if="loadingAccountInfo" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsAccountInfo" :items="itemsAccountInfo"
          v-model:current="currentItemAccountInfo" />
      </div>
      <div class="panel">
        <div class="filter-panel">
          <mo-search v-model="queryAccountInfo" @keyup:Enter="keyupEnterSearchAccountInfo" placeholder="Поиск" />
          <mo-datetime-range :modelValue="{ from: dateBeginAccountInfo, to: dateEndAccountInfo }"
            @update:modelValue="changeDateBeginAccountInfo" />
          Тип операции:
          <mo-select :modelValue="opAccountInfo" @update:modelValue="changeOpTypeAccountInfo"
            :options="billingOperations" />
        </div>
        <mo-paginator-2 v-bind:currentPage="currentPageAccountInfo" :maxPage="maxPageAccountInfo"
          @pageChange="pageChangeAccountInfo" />
      </div>
    </div>
    <!-- <hr style="color:#fcfbfb; border-width:2px; margin-block-end:30px;"/> -->
    <div class="billing-exports-block">
      <div style="height: 16em;">
        <div v-if="loadingBillingExports" class="loader">
          <mo-loader />
        </div>
        <mo-table v-else :columns="columnsBillingExports" :items="itemsBillingExports"
          v-model:current="currentItemBillingExports" />
      </div>
      <div class="panel">
        <div class="panel">

          <mo-datetime-range :modelValue="{ from: dateBeginBillingExports, to: dateEndBillingExports }"
            @update:modelValue="changeDateBillingExports" /> Статус операции:
          <mo-select :modelValue="opStatusBillingExports" @update:modelValue="changeOpStatusBillingExports"
            :options="billingStatus" />
        </div>
        <mo-paginator-2 v-bind:currentPage="currentPageBillingExports" :maxPage="maxPageBillingExports"
          @pageChange="pageChangeBillingExports" />
      </div>
    </div>

  </div>
</template>

<script>
const columnsAccountInfo = [
  { name: "id", title: "№", width: 10 },
  { name: "debit", title: "Дебет", width: 10 },
  { name: "credit", title: "Кредит", width: 10 },
  { name: "date", title: "Дата", width: 10, type: "DateTime" },
  { name: "description", title: "Описание", width: 500 },
];
const columnsBillingExports = [
  { name: "Id", title: "№", width: 10 },
  { name: "date", title: "Дата", width: 100 },
  { name: "amount", title: "Объем", width: 100 },
  { name: "account", title: "Счет", width: 100 },
  { name: "comment", title: "Комментарий", width: 500 },
  { name: "status", title: "Статус", width: 100 },
];

export default {
  name: 'call-center-tab2',
  props: {
    companyItem: {
      type: Object,
    }
  },
  data() {
    return {
      //Общие переменные
      billingOperations: [],
      billingStatus: [],
      billingStatusMap: [],
      //Переменные баланса
      dateBegin: "",
      companyBalance: {},
      loadingBalanceCompany: true,
      //Переменные для верхней таблицы пользоатели компании
      columnsAccountInfo,
      itemsAccountInfo: [],
      loadingAccountInfo: false,
      currentPageAccountInfo: 0,
      maxPageAccountInfo: false,
      limitAccountInfo: 6,
      dateBeginAccountInfo: undefined,
      dateEndAccountInfo: undefined,
      queryAccountInfo: "",
      opTypeAccountInfo: "",
      currentItemAccountInfo: [],
      opAccountInfo: "",
      //offsetAccountInfo: 0,
      //Переменные для нижней таблицы исория звонков
      columnsBillingExports,
      itemsBillingExports: [],
      loadingBillingExports: false,
      currentItemBillingExports: {},
      currentPageBillingExports: 0,
      limitBillingExports: 6,
      offsetBillingExports: 0,
      maxPageBillingExports: false,
      pageBillingExports: 1,
      pagesBillingExports: 1,
      commentBillingExports: "",
      opStatusBillingExports: "",
      dateBeginBillingExports: undefined,
      dateEndBillingExports: undefined,
    }
  },
  methods: {
    async pageChangeAccountInfo(event) {
      this.currentPageAccountInfo = event;
      this.loadAccountInfo();
    },
    async pageChangeBillingExports(event) {
      this.currentPageBillingExports = event;
      this.loadBillingExports();
    },
    async loadAccountInfo() {
      this.loadingAccountInfo = true;

      let dateBegin = undefined;
      let dateEnd = undefined;
      if (typeof (this.dateBeginAccountInfo) === 'object') dateBegin = this.dateBeginAccountInfo.toISOString().slice(0, 10);
      if (typeof (this.dateEndAccountInfo) === 'object') dateEnd = this.dateEndAccountInfo.toISOString().slice(0, 10);

      const { success, message, data: responseData } = await this.$http.getAccountInfoById(
        this.companyItem.Id,
        this.limitAccountInfo,
        this.currentPageAccountInfo * 6,
        this.queryAccountInfo,
        dateBegin,
        dateEnd,
        this.opTypeAccountInfo
      );

      this.itemsAccountInfo = responseData || [];
      if (this.itemsAccountInfo.length > 0) this.maxPageAccountInfo = false;
      if (this.itemsAccountInfo.length < 6) this.maxPageAccountInfo = true;

      this.loadingAccountInfo = false;
    },
    decodeBillingStatus() {
      let d = this.itemsBillingExports || [];
      d.forEach((item, i) => {
        d[i]['status'] = this.billingStatusMap[item['status']] || item['status'];
      })
      this.itemsBillingExports = d;
    },
    async loadBillingExports() {
      this.loadingBillingExports = true;

      let dateBegin = undefined;
      let dateEnd = undefined;
      if (typeof (this.dateBeginBillingExports) === 'object') dateBegin = this.dateBeginBillingExports.toISOString().slice(0, 10);
      if (typeof (this.dateEndBillingExports) === 'object') dateEnd = this.dateEndBillingExports.toISOString().slice(0, 10);

      const { success, message, data: responseData } = await this.$http.getBillingExportsById(
        this.companyItem.Id,
        this.limitBillingExports,
        this.currentPageBillingExports * 6,
        dateBegin,
        dateEnd,
        this.opStatusBillingExports
      );
      if (success) {
        let d = responseData;
        if (this.billingStatus.length > 0) {
          d.forEach((item, i) => {
            d[i]['status'] = this.billingStatusMap[item['status']] || item['status'];
          })
        }

        this.itemsBillingExports = responseData || [];
        if (this.itemsBillingExports.length > 0) this.maxPageBillingExports = false;
        if (this.itemsBillingExports.length < 6) this.maxPageBillingExports = true;
      }
      this.loadingBillingExports = false;
    },
    async getCompanyBalanceById() {
      this.loadingBalanceCompany = true;
      this.disableSave = true;
      const { success, message, data: responseData } = await this.$http.getCompanyBalanceById(
        this.companyItem.Id
      );
      this.companyBalance = responseData || {}
      this.loadingBalanceCompany = false;
    },
    async getCommentBillingExports() {
      this.disableSave = true;
      const { success, message, data: responseData } = await this.$http.getCommentBillingExports(
        this.currentItemBillingExports.Id
      );
      this.currentCommentBillingExports = responseData;
      if (success) this.disabelInput = false;
    },
    async saveCommentBillingExports() {
      const { success, message, data: responseData } = await this.$http.saveCommentBillingExports(
        this.currentItemBillingExports.Id,
        this.currentCommentBillingExports
      );
      this.$notify({ title: "Комментарий сохранен" });

    },
    async setComment(comment) {
      if (this.commentBillingExports != comment) this.disableSave = false;
      this.currentCommentBillingExports = comment;
    },
    async loadBillingOperations() {
      const { success, message, data: responseData } = await this.$http.getBillingOperations();
      if (success) {
        let d = responseData || [];
        let a = [];
        d.forEach(e => {
          a.push({ text: e['name'], value: e['code'] })
        });
        this.billingOperations = a;
      }
    },
    async loadBillingStatus() {
      const { success, message, data: responseData } = await this.$http.getRefBillingStatus();
      if (success) {
        let d = responseData || [];
        let a = [];
        let b = [];
        
        d.forEach(e => {
          a.push({ text: e['name'], value: e['code'] })
          b[e['code']] = e['name'];
        });
        this.billingStatusMap = b;
        this.billingStatus = a;
        this.decodeBillingStatus();
      }
    },
    async keyupEnterSearchAccountInfo(value) {
      this.queryAccountInfo = value;
      this.loadAccountInfo();
    },
    async changeDateBeginAccountInfo(value) {
      if (value['from'] !== undefined && value['from'] !== '') this.dateBeginAccountInfo = value['from'];
      if (value['to'] !== undefined && value['to'] !== '') this.dateEndAccountInfo = value['to'];
      this.loadAccountInfo();
    },
    async changeOpTypeAccountInfo(value) {
      this.opTypeAccountInfo = value;
      this.loadAccountInfo();
    },
    async changeDateBillingExports(value) {
      if (value['from'] !== undefined && value['from'] !== '') this.dateBeginBillingExports = value['from'];
      if (value['to'] !== undefined && value['to'] !== '') this.dateEndBillingExports = value['to'];
      this.loadBillingExports();
    },
    async changeOpStatusBillingExports(value) {
      this.opStatusBillingExports = value;
      this.loadBillingExports();
    }
  },
  mounted() {
    this.loadBillingOperations();
    this.loadBillingStatus();
    this.loadAccountInfo();
    this.loadBillingExports();
    this.getCompanyBalanceById()
  }
}
</script>

<style lang="scss" scoped>
.cliring-root {
  width: 100%;
  height: 100%;

  .balance {
    @include flex(row, space-between, right);

    :nth-child(2n) {
      color: #7e7f80;
      // >span:nth-child(2){color: red;}
      // >span:nth-child(4){color: green;}
      // >span{color: blue;}
    }
  }

  .account-info-block {
    width: 100%;
    height: 100%;
    @include flex(column, flex-start, stretch);

    .panel {
      @include flex(row, space-between, right);
    }

    .loader {
      padding: 50px 0;
      @include flex();
    }
  }

  .billing-exports-block {
    width: 100%;
    height: 100%;
    margin-top: 40px;
    @include flex(column, flex-start, stretch);

    .panel {
      @include flex(row, space-between, right);
    }

    .loader {
      padding: 50px 0;
      @include flex();
    }
  }

  .filter-panel {
    @include flex(row, space-between, start);
  }
}
</style>
